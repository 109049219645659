@import '../../../styles/common.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infoBox {
    display: flex;
    align-items: center;
    gap: 24px;

    .imgBox {
      img {
        width: 150px;
        max-height: 200px;
        border-radius: 10px;
        object-fit: contain;
      }
      .noImage {
        background-color: rgb(229, 229, 229);
        width: 150px;
        height: 150px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
      }
    }
    .infoDetail {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      // align-items: center;
      gap: 10px;
      padding: 12px 0;

      .storeName {
        font-weight: 700;
        font-size: 20px;
      }
      .storeAdr {
        font-weight: 500;
        font-size: 14px;
        color: #a4a4a4;
      }

      .storeReview {
        display: flex;
        align-items: flex-end;
        font-size: 15px;
        font-weight: 500;
        gap: 12px;
        color: #8d8d8d;
        span {
          margin-left: 10px;
        }
        .reviewCount {
          color: rgb(37, 37, 37);
        }

        .reviewdiff {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .reviewdiffText {
          font-size: 12px;
          color: #b4b4b4;
        }
      }

      .btnContainer {
        display: flex;
        justify-content: space-between;
        // padding: 0px 30px 20px 30px;
        font-size: 15px;
        gap: 10px;
        margin-top: 10px;

        button {
          width: 200px;
          border: none;
          border-radius: 6px;
          padding: 10px 30px;
          background-color: $main;
          color: $white;

          font-weight: 700;
          transition: all 0.2s ease;
          &:hover {
            background-color: $mainhover;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .infoBox {
      display: flex;
      flex-direction: column; /* 세로 정렬 */
      align-items: flex-start;
      gap: 6px;
      width: 100%;

      .imgBox {
        display: none;
        img {
          width: 80px;
          border-radius: 6px;
        }
        .noImage {
          background-color: rgb(229, 229, 229);
          width: 80px;
          height: 80px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 14px;
        }
      }
      .infoDetail {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%; /* 전체 너비 차지 */

        .storeName {
          font-weight: 700;
          font-size: 15px;
        }
        .storeAdr {
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 2px;
        }

        .storeReview {
          display: flex;
          align-items: flex-end;
          font-weight: 500;
          gap: 2px;
          font-size: 12px;

          span {
            color: #b4b4b4;
          }

          .reviewdiff {
            display: flex;
            align-items: center;
            gap: 2px;
          }
          .reviewdiffText {
            font-size: 10px;
            margin-left: 4px;
          }
        }
        .btnContainer {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          gap: 10px;
          margin-top: 6px;
          width: 100%;

          button {
            width: 100%;
            border: none;
            border-radius: 6px;
            font-size: 13px;
            padding: 10px 10px;
          }
        }
      }
    }
  }
}
