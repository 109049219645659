@import '../../styles/common.module.scss';

.dateContainer {
  position: relative;
  z-index: 10;
}

.calendarButton {
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.calendarButton svg {
  margin-left: 4px;
}
@media screen and (max-width: 576px) {
  .calendarButton {
    font-size: 14px; // 또는 원하는 글씨 크기
  }
}
