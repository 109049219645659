@import '../../styles/common.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infoBox {
    display: flex;
    align-items: center;
    gap: 24px;

    .imgBox {
      img {
        width: 120px;
        border-radius: 10px;
      }
      .noImage {
        background-color: rgb(229, 229, 229);
        width: 120px;
        height: 120px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
      }
    }
    .infoDetail {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .storeName {
        font-weight: 700;
        font-size: 20px;
        display: flex;
        align-items: flex-end;

        span {
          margin-left: 12px;
          font-size: 16px;
          border-bottom: 1px solid $main;
          color: $main;
        }
      }
      .storeAdr {
        font-weight: 500;
        font-size: 14px;
        color: #a4a4a4;
        margin-bottom: 4px;
      }

      .storeReview {
        display: flex;
        align-items: flex-end;
        font-weight: 500;
        gap: 6px;
        color: #8d8d8d;

        .reviewcount {
          font-size: 14px;
        }

        .reviewdiff {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .reviewdiffText {
          font-size: 12px;
          color: #b4b4b4;
        }
      }
    }
  }

  .keywordBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;

    .storeUpdate {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $white;
      background-color: $main; // 밝은 주황색 배경
      padding: 6px 12px; // 패딩 추가
      border-radius: 4px; // 둥근 모서리
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // 그림자 효과
      font-size: 15px;
      font-weight: 700;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: $mainhover; // 호버 시 색상 변경
      }

      span {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        margin: 0; // 기본 마진 제거
        padding: 0; // 기본 패딩 제거
      }

      .updateDetails {
        display: flex;
        align-items: center; // 아이콘과 텍스트를 세로로 중앙 정렬
        gap: 4px; // 요소들 사이의 간격

        svg {
          width: 14px;
          height: 14px;
          fill: #fff; // 아이콘 색상 변경
        }
      }
    }

    .keyword {
      display: flex;
      justify-content: space-between;
      text-align: center;
      gap: 24px;
    }
    .keywordTitle {
      color: #8d8d8d;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .keywordCount {
      font-weight: 700;
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 976px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .infoBox {
      display: flex;
      align-items: center;
      width: 100%;
      .imgBox {
        display: none;
      }
      .infoDetail {
        display: flex;
        flex-direction: column;

        .storeReview {
          display: flex;
          align-items: flex-end;

          .reviewdiff {
            display: flex;
            align-items: center;
          }
          .reviewdiffText {
          }
        }
      }
    }

    .keywordBox {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 4px;
      gap: 0px;
      width: 100%;
      margin-top: 20px;

      .storeUpdate {
        flex-direction: column; // 가로 방향으로 요소들을 나열
        justify-content: space-between; // 요소들을 양 끝으로 정렬
        align-items: flex-end;
        gap: 4px; // 요소들 사이의 간격

        .updateDetails {
          flex-direction: row; // 모바일에서는 아이콘과 날짜를 한 줄에 표시
          justify-content: center; // 가운데 정렬
        }
      }

      .keyword {
        display: flex;
        justify-content: center;
        text-align: center;
        // width: 100%;
        gap: 10px;
      }
      .keywordTitle {
        color: #8d8d8d;
        font-weight: 500;
        margin-bottom: 4px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .infoBox {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 100%;

      .imgBox {
        display: none;
      }
      .infoDetail {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .storeName {
          font-size: 15px;
          span {
            margin-left: 8px;
            font-size: 13px;
          }
        }
        .storeAdr {
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0px;
        }

        .storeReview {
          display: flex;
          align-items: flex-end;
          font-weight: 500;
          gap: 2px;
          font-size: 12px;

          span {
            color: #b4b4b4;
          }
          .reviewcount {
            font-size: 12px;
          }

          .reviewdiff {
            display: flex;
            align-items: center;
            gap: 2px;
          }

          .reviewdiffText {
            font-size: 10px;
            margin-left: 4px;
          }
        }
      }
    }

    .keywordBox {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 4px;
      gap: 0px;
      width: 100%;
      margin-top: 10px;

      .storeUpdate {
        font-size: 12px;
        flex-direction: column; // 가로 방향으로 요소들을 나열
        justify-content: space-between; // 요소들을 양 끝으로 정렬
        align-items: flex-end;
        gap: 4px; // 요소들 사이의 간격

        .updateDetails {
          flex-direction: row; // 모바일에서는 아이콘과 날짜를 한 줄에 표시
          justify-content: center; // 가운데 정렬
        }
        span {
          font-size: 14px;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }

      .keyword {
        display: flex;
        justify-content: center;
        text-align: center;
        // width: 100%;
        gap: 10px;
      }
      .keywordTitle {
        color: #8d8d8d;
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .keywordCount {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
