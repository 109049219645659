@import '../../styles/common.module.scss';

.container {
  // max-width: 860px;
  width: 100%;
  font-weight: 500;
  padding: 0 20px;
}

.header {
  font-size: 18px;
  font-weight: 500;
  img {
    display: inline-block;
    width: 20px;
  }

  span {
    margin-left: 8px;
  }
}

.keywordTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.keywordBox {
  display: flex;
  flex-direction: column;
}

.findKeyword {
  font-size: 24px;
  font-weight: 700;
  color: $main;
}

.updateTime {
  margin-top: 10px;
  font-size: 15px;
  color: #777777;
}

.keywordInfo {
  .infoList {
    display: flex;
    gap: 50px;
    padding-right: 10px;
  }
  .staticLayout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  text-align: center;
  .statics {
    font-size: 20px;
    font-weight: 700;
    color: #414141;
  }
  .staticIndex {
    font-size: 14px;
    color: #8e8e8e;
  }
  .staticName {
    font-size: 15px;
    color: #777777;
  }
}

.categoryTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px 0;
}

.tabTitle {
  font-size: 15px;
  font-weight: 700;
}

.btnList {
  display: flex;
  gap: 8px;
  .btn {
    width: 32px;
    cursor: pointer;
    // border: 1px solid gray;
  }
}
.listBox {
  // border: 1px solid #969696;
  // border-radius: 5px;
  // padding: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.keywordTag {
  display: flex;
  align-items: center;
  height: 40px;
  // border: 1px solid #e0e0e0;
  border-radius: 5px;
  // background: #f7f9fd;
  font-size: 14px;
  font-weight: 700;
  // color: #2f2f2f;
  text-align: center;
  padding: 7px 10px;

  border: none;
  background: #6b6b6b;
  color: #ffffff;
}

.tagDelete {
  border: none;
  background: none;
  margin-right: 10px;
  font-weight: 700;
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0;
  }

  .header {
    font-size: 16px;
  }

  .keywordTab {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .findKeyword {
    font-size: 20px;
  }

  .updateTime {
    margin-top: 4px;
    font-size: 14px;
  }

  .keywordInfo {
    width: 100%;
    border-top: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    padding-top: 10px;
    padding-bottom: 10px;

    .infoList {
      display: flex;
      justify-content: space-between;
      gap: 0;
      padding-right: 0px;
    }
    .staticLayout {
      flex: 1; /* 각 항목이 동일한 너비를 가지도록 설정 */
    }
    .statics {
      font-size: 16px;
      font-weight: 700;
    }
    .staticIndex {
      font-size: 10px;
    }
    .staticName {
      font-size: 13px;
    }
  }

  .categoryTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px 0;
  }

  .tabTitle {
    font-size: 14px;
  }

  .btnList {
    .btn {
      width: 24px;
    }
  }

  .listBox {
    margin-bottom: 20px;
    gap: 6px;
  }

  .keywordTag {
    font-size: 12px;
    height: 28px;
    border-radius: 4px;
    padding: 7px 8px;
  }

  .tagDelete {
    font-size: 12px;
    margin-right: 6px;
  }
}
