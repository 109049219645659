@import '../../styles/common.module.scss';

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 240px;
  height: 100%;
  background-color: $bg;
  color: $white;
  z-index: 101;
  padding: 60px 24px;
}

.nav_logo {
  margin-bottom: 80px;
  cursor: pointer;
}

.nav_header {
  margin-bottom: 30px;
}

.nav_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.nav_item {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  border-radius: 8px;
  padding: 14px 10px 14px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 40px;
  }

  &:hover {
    color: $main;
  }
  &:hover img {
    filter: invert(58%) sepia(96%) saturate(3553%) hue-rotate(344deg)
      brightness(100%) contrast(102%);
  }
}

.nav_item.selected {
  background-color: $main;

  &:hover {
    color: $white;
  }
  &:hover img {
    filter: none;
  }
}

// Add styles for sub menu items
.sub_nav_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 10px;
}

.sub_nav_item {
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  border-radius: 8px;
  padding: 5px 20px 5px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $main;
  }

  &.selected {
    color: $main;
  }
}

@media screen and (max-width: 976px) {
  .sidebar {
    display: none;
  }
}
