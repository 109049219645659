@import '../../styles/common.module.scss';

.container {
  width: 100%;
  padding: 0;
}

.scrollable .table_box {
  max-height: 400px; // 원하는 높이로 설정
  overflow-y: auto; // 수직 스크롤을 가능하게 함
  border-bottom: 1px solid rgb(220, 220, 220);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #d2d2d2;
  }

  &::-webkit-scrollbar-thumb {
    background: #7d7d7d;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #646464;
  }
}

.table {
  width: 100%;
  // border-top: 2px solid black;

  table-layout: fixed;

  // border-bottom: 2px solid rgba(188, 188, 188, 0.6);

  thead th {
    // border-bottom: 1px solid rgb(70, 70, 70);
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    position: sticky;
    top: 0;
    background: white; // 배경을 흰색으로 설정하여 스크롤시 다른 콘텐츠가 보이지 않게 함
    z-index: 1; // 다른 요소 위에 표시

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid rgb(70, 70, 70);
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top: 2px solid black; // 원하는 테두리 스타일
      z-index: 2;
    }
  }

  th,
  td {
    vertical-align: middle;
    // padding: 20px 20px 20px 20px;
    // border-top: 2px solid rgba(188, 188, 188, 0.6);
    // border-bottom: 2px solid rgba(188, 188, 188, 0.6);
  }
  tbody th {
    padding: 20px 20px 20px 20px;
  }

  .left {
    text-align: left;
  }

  .diagnosis {
    text-align: center;
  }
  .center {
    text-align: center;
    // display: flex;
    // align-items: center;
    // width: 100%;
  }

  .truncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .resultButton {
    // 결과보기 버튼에 대한 스타일
    color: $main;
    border: 1px solid $main;
    padding: 2px 4px;
    font-size: 13px;
    font-weight: 500;
  }

  .diagnoseButton {
    // 상세진단 버튼에 대한 스타일
    color: $main;
    border: 1px solid $main;
    padding: 2px 4px;
    font-size: 13px;
    font-weight: 500;
  }
  tbody tr:first-child td {
    padding: 14px 10px 10px 10px; // 첫 번째 행에만 적용
  }

  tbody tr:last-child td {
    padding: 10px 10px 14px 10px; // 마지막 행에만 적용
  }

  tbody tr td {
    padding: 15px 10px 15px 10px; // 나머지 행에 적용
    border-bottom: 1px solid rgb(220, 220, 220);
  }
  td {
    font-weight: 500;
    font-size: 15px;
  }

  th {
    font-weight: 700;
    // background-color: rgb(229, 229, 229);
    // border-bottom: 1px solid rgba(188, 188, 188, 0.6);
    img {
      width: 8px;
      display: inline-block;
    }
  }

  .tableKeyword {
    font-weight: 700;
  }
}
.table_min {
  min-width: 800px;
}

.KeywordBox:not(:last-child) {
  border-bottom: 1px solid #dddddd; /* 원하는 border 스타일로 변경하세요 */
}

.listItem {
  margin-bottom: 30px; // adjust this value to change the space between items
}
.linkHover:hover {
  text-decoration: underline;
}

.normal {
  color: $main; // 주황색
}

.omitted {
  color: #666666; // 회색
}

.alingBtn {
  margin-left: 8px;
}

.keywordCheckBtn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  // border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.keywordCheckBtn:checked {
  background-color: $main;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loadMoreButton {
  border: none;
  border-radius: 100px;
  padding: 10px 16px;
  background-color: $main;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s ease;

  &:hover {
    background-color: $mainhover;
  }
}

/* modal overlay */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
  display: none;
}

.overlay.show {
  display: block;
}

@media screen and (max-width: 976px) {
  /*normal*/
  .table_box {
    overflow-x: auto;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0 10px;
  }

  .table {
    thead th {
      padding: 8px 10px 8px 10px;
      font-size: 13px;
    }

    th,
    td {
    }
    tbody th {
      padding: 10px 10px 10px 10px;
    }
    td {
      padding: 10px 10px 10px 10px;
      font-size: 12px;
    }

    th {
      font-weight: 700;
      // background-color: rgb(229, 229, 229);
      // border-bottom: 1px solid rgba(188, 188, 188, 0.6);
    }
  }
  .table_min {
    min-width: 600px;
  }

  .listItem {
    margin-bottom: 15px; // adjust this value to change the space between items
  }

  .btnContainer {
    margin-top: 16px;
  }
  .loadMoreButton {
    padding: 8px 12px;
    font-size: 12px;
  }
}
