@import '../../styles/common.module.scss';

.container {
  // max-width: 860px;
  width: 100%;
  font-weight: 500;
  padding: 0 20px;
  margin-top: 30px;
}

.header {
  font-size: 18px;
  font-weight: 700;
  img {
    display: inline-block;
    width: 20px;
  }

  span {
    margin-left: 8px;
    font-weight: 500;
  }
}

.compareList {
  padding: 20px 10px 20px 10px;
  margin-bottom: 30px;
  .listTitle {
    padding: 16px 8px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .description {
    font-size: 13px;
    color: #666666;
    margin-left: 30px;
  }

  .listItem {
    min-height: 40px;
    padding: 6px 8px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 15px;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
}

.searchButton {
  border: none;
  border-radius: 6px;
  padding: 8px 10px 8px 14px;
  background-color: $main;
  color: $white;
  font-size: 17px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;

  &:hover {
    background-color: $mainhover;
  }
}

// 리액트 아이콘 반응형 크기 조절
.searchButton {
  svg {
    width: 26px;
    height: 26px;
  }

  @media screen and (max-width: 576px) {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.keywordTag {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 7px 10px;
  border: none;
  background: #6b6b6b;
  color: #ffffff;
}

.tagDelete {
  border: none;
  background: none;
  margin-right: 10px;
  font-weight: 700;
}

.tableLayout {
  padding-top: 10px;
}
.findKeyword {
  margin-bottom: 60px;
}

.findStore {
  margin-bottom: 60px;
}

.mobileBtnBox {
  display: none;
}

.loading {
  font-size: 16px;
}

/* modal overlay */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
  display: none;
}

.overlay.show {
  display: block;
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0;
  }

  .header {
    font-size: 16px;
  }

  .compareList {
    padding: 10px 0px 10px 0px;
    margin-bottom: 20px;
    .listTitle {
      font-size: 14px;
      padding: 8px 8px;
    }
    .description {
      font-size: 12px;
      margin-left: 30px;
    }

    .listItem {
      min-height: 28px;
      padding: 6px 8px;
      font-size: 15px;
      gap: 10px;
    }
  }
  .searchButton {
    display: none;
  }

  .mobileBtnBox {
    // display: block;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  }
  .mobileSearchButton {
    border: none;
    border-radius: 6px;
    padding: 8px 10px 8px 14px;
    background-color: $main;
    color: $white;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s ease;
    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: $mainhover;
    }
  }

  .keywordTag {
    height: 28px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    padding: 7px 8px;
  }

  .tagDelete {
    margin-right: 4px;
  }

  .tableLayout {
    padding-top: 0;
  }
  .findKeyword {
    margin-bottom: 20px;
  }

  .findStore {
    margin-bottom: 20px;
  }

  .loading {
    font-size: 14px;
  }
}
