@import '../../styles/common.module.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-left: 240px;
  padding: 5px;
}
.layout {
  max-width: 960px;
  margin: 0 auto;
}
.contents {
  width: 100%;
  text-align: center;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  svg {
    width: 28px;
    height: 28px;
  }
}

.menuIcon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.navBox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav {
  cursor: pointer;

  &:hover {
    font-weight: 600;
    text-decoration: underline;
    // color: red;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none;
  transition: opacity 0.3s ease-in-out;
}

.overlay.show {
  display: block;
}

@media screen and (max-width: 976px) {
  .menuIcon {
    display: block;
  }
  .container {
    margin-left: 0;
  }
}

@media screen and (max-width: 576px) {
  .contents {
    height: 22px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .navBox {
    gap: 6px;
    font-size: 14px;
  }
}
