@import '../../styles/common.module.scss';

.container {
  width: 100%;
  padding: 0 20px;
}

.table {
  width: 100%;
  border-top: 2px solid black;
  table-layout: fixed;

  // border-bottom: 2px solid rgba(188, 188, 188, 0.6);

  thead th {
    border-bottom: 1px solid rgb(70, 70, 70);
    padding: 10px 10px 10px 10px;
    font-size: 15px;
  }

  th,
  td {
    vertical-align: middle;
    // padding: 20px 20px 20px 20px;
    // border-top: 2px solid rgba(188, 188, 188, 0.6);
    // border-bottom: 2px solid rgba(188, 188, 188, 0.6);
  }
  tbody th {
    padding: 20px 20px 20px 20px;
  }

  .left {
    text-align: left;
  }

  .diagnosis {
    text-align: center;
  }
  .center {
    text-align: center;
    // display: flex;
    // align-items: center;
    // width: 100%;
  }

  .truncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .resultButton {
    // 결과보기 버튼에 대한 스타일
    color: $main;
    border: 1px solid $main;
    padding: 2px 4px;
    font-size: 13px;
    font-weight: 500;
  }

  .diagnoseButton {
    // 상세진단 버튼에 대한 스타일
    color: $main;
    border: 1px solid $main;
    padding: 2px 4px;
    font-size: 13px;
    font-weight: 500;
  }
  tbody tr:first-child td {
    padding: 20px 10px 10px 10px; // 첫 번째 행에만 적용
  }

  tbody tr:last-child td {
    padding: 10px 10px 20px 10px; // 마지막 행에만 적용
  }

  tbody tr td {
    padding: 15px 10px 15px 10px; // 나머지 행에 적용
  }
  td {
    font-weight: 500;
    font-size: 15px;
  }

  th {
    font-weight: 700;
    // background-color: rgb(229, 229, 229);
    // border-bottom: 1px solid rgba(188, 188, 188, 0.6);
  }
}
.table_min {
  min-width: 680px;
}

.KeywordBox:not(:last-child) {
  border-bottom: 1px solid #dddddd; /* 원하는 border 스타일로 변경하세요 */
}

.listItem {
  margin-bottom: 30px; // adjust this value to change the space between items
}
.linkHover:hover {
  text-decoration: underline;
}

.normal {
  color: $main; // 주황색
}

.omitted {
  color: #666666; // 회색
}

.keywordCheckBtn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  // border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.keywordCheckBtn:checked {
  background-color: $main;
}

/* modal overlay */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
  display: none;
}

.overlay.show {
  display: block;
}

@media screen and (max-width: 976px) {
  /*normal*/
  .table_box {
    overflow-x: auto;
  }
  .table_min {
    min-width: 820px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0 10px;
  }

  .table {
    thead th {
      padding: 8px 10px 8px 10px;
      font-size: 13px;
    }

    th,
    td {
    }
    tbody th {
      padding: 10px 10px 10px 10px;
    }
    td {
      padding: 10px 10px 10px 10px;
      font-size: 12px;
    }

    th {
      font-weight: 700;
      // background-color: rgb(229, 229, 229);
      // border-bottom: 1px solid rgba(188, 188, 188, 0.6);
    }
    .resultButton {
      // 결과보기 버튼에 대한 스타일
      font-size: 12px;
    }

    .diagnoseButton {
      // 상세진단 버튼에 대한 스타일
      font-size: 12px;
    }
  }
  .table_min {
    min-width: 720px;
  }

  .listItem {
    margin-bottom: 15px; // adjust this value to change the space between items
  }
}
