@import '../../styles/common.module.scss';
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}

.header {
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 8px;
  margin-bottom: 4px;
  font-weight: 500;

  .headerRight {
    font-size: 18px;
  }
  .headerLeft {
    display: flex;
    gap: 10px;
    font-size: 14px;
  }
}

.storeBox {
  max-width: 100%;
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  font-weight: 500;
}

.storeInfo {
  display: flex;
  align-items: center;
}

.storeDetail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  max-width: 350px;

  .storeName {
    font-size: 18px;
  }
  .storeAdr {
    font-size: 15px;
    color: #606060;
    word-wrap: break-word;
  }
}

.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
  }
  .noImage {
    background-color: rgb(229, 229, 229);
    width: 100px;
    height: 100px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navBtn {
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: $main;
  color: white;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s ease;
  &:hover {
    background-color: $mainhover;
  }
}
.deleteBtn {
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: rgb(171, 171, 171);
  color: $white;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s ease;
  &:hover {
    background-color: rgb(129, 129, 129);
  }
}

@media screen and (max-width: 976px) {
  .container {
    padding: 10px 0;
  }

  .header,
  .storeBox {
    width: 90%; /* 화면 너비에 맞게 조정 */
    max-width: 600px;
  }
  .storeBox {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-width: 600px;
  }

  .storeInfo {
    // flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

  .storeDetail {
    align-items: flex-end;
    gap: 8px;
    padding: 0px;
    max-width: 300px;
    text-align: right;

    .storeName {
      font-size: 14px;
    }
    .storeAdr {
      font-size: 12px;
    }
  }

  .btnContainer {
    gap: 10px;
    width: 100%;
  }

  .navBtn {
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
  }
  .deleteBtn {
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 10px 0;
  }
  .header {
    padding: 0 8px;
    margin-bottom: 4px;

    .headerRight {
      font-size: 16px;
    }
    .headerLeft {
      gap: 10px;
      font-size: 12px;
    }
  }

  .storeBox {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .storeInfo {
    // flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

  .imgBox {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
    }
    .noImage {
      width: 60px;
      height: 60px;

      font-size: 11px;
    }
  }
  .storeDetail {
    align-items: flex-end;
    gap: 8px;
    padding: 0px;
    max-width: 180px;
    text-align: right;

    .storeName {
      font-size: 14px;
    }
    .storeAdr {
      font-size: 12px;
    }
  }

  .btnContainer {
    gap: 10px;
    width: 100%;
  }

  .navBtn {
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
  }
  .deleteBtn {
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
  }
}
