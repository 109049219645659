@import '../../styles/common.module.scss';

.table {
  width: 100%;
  border-top: 2px solid rgba(188, 188, 188, 0.6);
  border-bottom: 2px solid rgba(188, 188, 188, 0.6);
  table-layout: fixed;

  th,
  td {
    text-align: left;
    vertical-align: middle;
    padding: 10px 30px 10px 30px;
    border-top: 2px solid rgba(188, 188, 188, 0.6);
    border-bottom: 2px solid rgba(188, 188, 188, 0.6);
  }

  td {
    font-weight: 500;
    font-size: 15px;
  }

  th {
    font-weight: 700;
    background-color: rgb(229, 229, 229);
  }
}

.table_min {
  min-width: 680px;
}

.keywordText {
  cursor: pointer;
}

.update {
  font-size: 10px;
  color: #555555;
}

.rankChange {
  display: flex;
  justify-content: center;
  align-items: center; /* This will center the content vertically */
  width: 60px;
  padding: 4px 0;
  border-radius: 4px;
}

.blueBackground {
  background-color: rgba(0, 153, 255, 0.3);
  color: rgb(0, 110, 255);
}

.redBackground {
  background-color: rgba(255, 50, 0, 0.3);
  color: rgb(255, 72, 0);
}

.grayBackground {
  background-color: rgba(138, 135, 126, 0.3);
  color: black;
}
.spinner {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 976px) {
  /*normal*/
  .table_box {
    overflow-x: auto;
  }
}
@media screen and (max-width: 576px) {
  .table {
    thead th {
      padding: 8px 10px 8px 10px;
      font-size: 13px;
    }

    th,
    td {
    }
    tbody th {
      padding: 10px 10px 10px 10px;
    }
    td {
      padding: 10px 10px 10px 10px;
      font-size: 12px;
    }

    th {
      font-weight: 700;
      // background-color: rgb(229, 229, 229);
      // border-bottom: 1px solid rgba(188, 188, 188, 0.6);
    }
  }
  .table_min {
    min-width: 540px;
  }
}
