.wrapper {
  height: 280px;
  background-color: #fbfafb;
  border-top: 1px solid #edf0f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  letter-spacing: -0.5px;
  font-size: 15px;
}

.terms {
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.info {
  font-weight: 500;
  color: #7e7e7e;
}

.company {
  font-weight: 500;
  color: #7e7e7e;
}

.copyright {
  font-size: 14px;
  color: #999999;
  margin-top: 1.5rem;
}
.spaceDash {
  display: inline;
  padding: 0 4px;
}
.spaceDashFirst {
  display: inline;
  padding: 0 4px;
}
@media screen and (max-width: 576px) {
  .wrapper {
    height: auto; /* 높이를 자동으로 조정 */
    padding: 20px 10px; /* 패딩 조정 */
  }

  .container {
    flex-direction: column; /* 항목을 수직으로 정렬 */
    align-items: center; /* 가운데 정렬 */
    gap: 8px; /* 간격 늘리기 */
    font-size: 13px; /* 폰트 크기 줄이기 */
  }

  .info,
  .company {
    display: flex;
    flex-direction: column; /* 항목을 수직으로 정렬 */
    align-items: center; /* 가운데 정렬 */
    text-align: center; /* 텍스트 가운데 정렬 */
    gap: 8px; /* 각 항목 사이 간격 조정 */
  }

  .info span,
  .company span {
    display: block; /* 각 span 요소를 블록 레벨 요소로 만들어 한 줄에 하나씩 표시 */
  }

  .spaceDash {
    display: none; /* '|' 기호 숨기기 */
  }

  .terms {
    margin-bottom: 1rem;
  }
  .copyright {
    margin-top: 1rem;
  }
}
