@import '../../styles/common.module.scss';

.wrapper {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 50px 40px 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
}

.logo {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 40px;
  font-family: 'Cafe24Ohsquare';
}

.container label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
}

.container input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 1rem;
  color: #333;
  margin-top: 5px;
}

.container button {
  max-width: 160px;
  width: 100%;
  padding: 10px 0px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  background-color: $main;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: $mainhover;
  }
}

.container button:disabled {
  background-color: $mainhover;
  cursor: not-allowed;
}

.container p {
  padding: 4px;
  color: #f00;
  font-size: 0.8rem;
}
.submitErr {
  text-align: center;
  padding: 4px;
  color: #f00;
  font-size: 0.8rem;
}

.phoneInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.phoneInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 1rem;
  color: #333;
  text-align: center; // 텍스트를 중앙 정렬로 만들어 줍니다.

  // &:not(:last-child) {
  //   margin-right: 10px; // 오른쪽 필드와 간격을 주기 위해 추가
  // }
}

.phoneDash {
  font-size: 1.2rem;
  color: #333;
  user-select: none; // 구분자를 선택할 수 없게 만듭니다.
  padding: 0 0.4rem;
}

.emailInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.emailInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 1rem;
  color: #333;

  &:first-child {
    flex-grow: 1; // 이메일 앞부분이 남은 공간을 모두 차지하도록 설정
  }
}

.emailDash {
  font-size: 1.2rem;
  color: #333;
  user-select: none; // 구분자를 선택할 수 없게 만듭니다.
  padding: 0 0.3rem;
}

// 도메인 선택을 위한 select 스타일링
.emailInputContainer select {
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 0.8rem;
  color: #333;
  width: auto; // 변경된 부분
  min-width: 80px; // 필요하다면 추가
  margin-left: 0.5rem;
}

// 입력 필드 오류 스타일링
.error {
  color: #f00;
  font-size: 0.8rem;
  margin-top: 5px;
}

@media screen and (max-width: 576px) {
  .container {
    padding: 30px 30px 30px 30px;
    max-width: 360px;
  }

  .logo {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .container label {
    margin-bottom: 12px;
    font-size: 1rem;
  }

  .container input {
    padding: 8px;
    font-size: 1rem;
  }

  .container button {
    max-width: 120px;
    padding: 10px 0px;
    margin-top: 10px;
    font-size: 16px;
  }

  .tag {
    margin-top: 30px;

    span {
      font-size: 14px;
    }
  }

  .sign {
    margin-top: 30px;
  }

  .phoneInput {
    padding: 8px;
    font-size: 1rem;
  }

  .phoneDash {
    font-size: 1rem;
    padding: 0 0.3rem;
  }

  .emailInput {
    width: calc(100% - 40px); // 작은 화면에서 너비 조정
    padding: 8px;
    font-size: 1rem;
  }

  .emailInputContainer select {
    padding: 2px;
    font-size: 0.8rem;
    min-width: 80px; // 필요하다면 추가
    margin-left: 0.5rem;
  }
  .emailDash {
    font-size: 0.8rem;
    padding: 0 0.2rem;
  }
}
