@import '../../../styles/common.module.scss';

.container {
  .entire {
    border: 1px solid rgba(188, 188, 188, 0.6);
    border-radius: 10px;
    padding: 30px;
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;

    height: 300px;

    .e_right {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
      span {
        color: $main;
      }
    }
    .e_center {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 210px;
      height: 210px;

      .scoreLabel {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .scoreValue {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 16px;
      }

      .scoreOutOf {
        font-size: 20px;
        font-weight: 500;
        color: #cccccc;
      }
    }
    .e_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .e_left_badge {
        display: flex;
        gap: 16px;
        .badge {
          position: relative; // 추가
          width: 44px;
          // height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          & > img {
            // width: 100%;
            // height: 100%;
          }
          & > span {
            position: absolute; // 추가
            color: white;
            font-weight: 700;
            font-size: 14px;
            z-index: 1;
          }
        }
      }

      .e_left_update {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 14px;
        line-height: 1.5;
        color: #979797;
      }
    }
  }

  .detail {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    .d_content {
      max-width: 280px;
      width: 100%;
      height: 220px;
      border: 1px solid rgba(188, 188, 188, 0.6);
      border-radius: 10px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .d_title {
        font-size: 20px;
        font-weight: 700;
      }
      .d_score {
        font-size: 36px;
        font-weight: 700;
      }
      .d_progress {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .inspection {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative; // 추가

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 10%,
        rgba(255, 255, 255, 0.9) 20%,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 1) 60%,
        rgba(255, 255, 255, 0.9) 80%,
        rgba(255, 255, 255, 0.5) 90%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 10;

      .overlayContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 50px;
        height: 100%;

        img {
          width: 140px;
          height: auto;
        }

        p {
          margin-top: 4px;
          font-size: 18px;
          text-align: center;
          line-height: 1.5;
        }
      }

      .overlayFooter {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $main;
        opacity: 1;
        color: white;
        text-align: center;
        padding: 24px;
        font-size: 20px;
        font-weight: 700;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .i_content {
    border: 1px solid rgba(188, 188, 188, 0.6);
    border-radius: 10px;
    // height: 150px;
    display: flex;
    position: relative; /* 상대 위치 설정 */
    overflow: hidden; /* 추가 */

    /* 가상 엘리먼트를 사용하여 배경색을 추가 */
    &::before {
      content: '';
      flex: 0 0 1.5%;
      display: block;
    }

    &.box1::before {
      background-color: #ffc940;
    }

    &.box2::before {
      background-color: #ff6524;
    }

    &.box3::before {
      background-color: #59c4fa;
    }

    .content {
      // flex: 1;
      // padding: 20px 24px;
      padding: 20px 24px 4px 24px;

      header {
        font-size: 20px;
        font-weight: 700;
      }
      .headerDes {
        font-size: 14px;
        color: #6e6e6e;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .text {
        font-size: 17px;
        margin-bottom: 10px;
      }
      .textWithDashWrapper {
        display: flex; // flexbox를 사용
        align-items: flex-start; // 아이템들을 선상에서 시작하도록 설정
        color: black;
        font-size: 15px;
      }

      .textWithDashIcon {
      }

      .textWithDashText {
        flex: 1; // 남은 공간을 모두 차지하도록 설정
        font-size: 17px;
        margin-bottom: 10px;
        margin-left: 6px;
        line-height: 1.2;
      }
      .textDesWrapper {
        display: flex; // flexbox를 사용
        align-items: flex-start; // 아이템들을 선상에서 시작하도록 설정
        color: $main;
        font-size: 15px;
      }

      .textDesIcon {
        margin-left: 10px; // 오른쪽 마진 추가
      }

      .textDesText {
        flex: 1; // 남은 공간을 모두 차지하도록 설정

        margin-bottom: 20px;
        margin-left: 6px;
        line-height: 1.2;
      }

      .keywordDetail {
        display: inline-block;
        background-color: $main;
        color: white;
        font-size: 14px;
        font-weight: 700;
        padding: 5px 15px 5px 15px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        margin: 20px 0 20px 0;
      }

      .userKeywords {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 700;
      }
      .localKeywords {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 700;
      }
      .keyworddes {
        padding-left: 8px;
        font-size: 15px;
        margin-bottom: 20px;
        color: #6e6e6e;
        line-height: 1.2;
      }
    }
  }
}

@media screen and (max-width: 976px) {
  .container {
    .entire {
      border: 1px solid rgba(188, 188, 188, 0.6);
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-direction: column;
      align-items: center; /* 모든 자식을 가로축 중앙 정렬 */
      height: auto; /* 높이 자동 조절 */
      // height: 300px;

      > * {
        width: 100%; /* 부모 컨테이너의 전체 너비 사용 */
        text-align: center; /* 텍스트 중앙 정렬 */
        margin-bottom: 10px; /* 아래쪽 여백 추가 */
      }

      // .e_right,
      // .e_left {
      //   width: 100%; /* 부모 컨테이너의 전체 너비 사용 */
      //   text-align: center; /* 텍스트 중앙 정렬 */
      //   margin-bottom: 10px; /* 아래쪽 여백 추가 */
      // }

      .e_right {
        // font-size: 16px;
      }
      .e_center {
        position: static;
        transform: none;
        width: 180px;
        height: auto; /* 높이를 자동으로 설정하여 내용물에 맞게 조정 */
        padding: 0px 0 8px 0; /* 적절한 여백을 제공 */
        /* 이미지나 내부 콘텐츠 크기에 맞게 너비를 조정합니다. */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto; /* 자동 마진으로 중앙 정렬 */

        .scoreLabel {
          // font-size: 14px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        .scoreValue {
          // font-size: 24px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        .scoreOutOf {
          // font-size: 14px;
          font-weight: 500;
          color: #cccccc;
        }
      }
      .e_left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0;

        .e_left_badge {
          display: flex;
          width: 100%; /* 전체 너비 사용 */
          justify-content: center; /* 가로 중앙 정렬 */

          gap: 16px;
          .badge {
            position: relative; // 추가
            // width: 36px;
            // height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > span {
              font-size: 12px;
            }
          }
        }

        .e_left_update {
          display: flex;
          width: 100%; /* 전체 너비 사용 */
          justify-content: center; /* 가로 중앙 정렬 */
          align-items: flex-end;
          margin-top: 10px;
          // font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    .entire {
      border: 1px solid rgba(188, 188, 188, 0.6);
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-direction: column;
      align-items: center; /* 모든 자식을 가로축 중앙 정렬 */
      height: auto; /* 높이 자동 조절 */
      // height: 300px;

      > * {
        width: 100%; /* 부모 컨테이너의 전체 너비 사용 */
        text-align: center; /* 텍스트 중앙 정렬 */
        margin-bottom: 10px; /* 아래쪽 여백 추가 */
      }

      // .e_right,
      // .e_left {
      //   width: 100%; /* 부모 컨테이너의 전체 너비 사용 */
      //   text-align: center; /* 텍스트 중앙 정렬 */
      //   margin-bottom: 10px; /* 아래쪽 여백 추가 */
      // }

      .e_right {
        font-size: 16px;
      }
      .e_center {
        position: static;
        transform: none;
        width: 140px;
        height: auto; /* 높이를 자동으로 설정하여 내용물에 맞게 조정 */
        padding: 0px 0 8px 0; /* 적절한 여백을 제공 */
        /* 이미지나 내부 콘텐츠 크기에 맞게 너비를 조정합니다. */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto; /* 자동 마진으로 중앙 정렬 */

        .scoreLabel {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        .scoreValue {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        .scoreOutOf {
          font-size: 14px;
          font-weight: 500;
          color: #cccccc;
        }
      }
      .e_left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0;

        .e_left_badge {
          display: flex;
          width: 100%; /* 전체 너비 사용 */
          justify-content: center; /* 가로 중앙 정렬 */

          gap: 16px;
          .badge {
            position: relative; // 추가
            width: 36px;
            // height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > span {
              font-size: 12px;
            }
          }
        }

        .e_left_update {
          display: flex;
          width: 100%; /* 전체 너비 사용 */
          justify-content: center; /* 가로 중앙 정렬 */
          align-items: flex-end;
          margin-top: 10px;
          font-size: 12px;
        }
      }
    }

    .detail {
      margin-top: 10px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;

      .d_content {
        max-width: 100%;
        width: 100%;
        height: auto;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;

        .d_title {
          font-size: 14px;
        }
        .d_score {
          font-size: 20px;
        }
        .d_progress {
          width: 100%;
          margin-top: 0px;
        }
      }
    }

    .inspection {
      margin-top: 15px;
      margin-bottom: 20px;
      gap: 30px;
    }

    .i_content {
      .content {
        padding: 20px 10px 0px 10px;

        header {
          font-size: 16px;
        }
        .headerDes {
          font-size: 13px;
          margin-top: 10px;
          margin-bottom: 15px;
        }
        .text {
          font-size: 17px;
          margin-bottom: 10px;
        }
        .textWithDashWrapper {
          font-size: 15px;
        }

        .textWithDashIcon {
        }

        .textWithDashText {
          font-size: 13px;
          margin-bottom: 6px;
          margin-left: 6px;
          line-height: 1.2;
        }
        .textDesWrapper {
          font-size: 13px;
        }

        .textDesIcon {
          margin-left: 10px; // 오른쪽 마진 추가
        }

        .textDesText {
          flex: 1; // 남은 공간을 모두 차지하도록 설정

          margin-bottom: 15px;
          margin-left: 4px;
          line-height: 1.2;
        }

        .keywordDetail {
          display: inline-block;
          background-color: $main;
          color: white;
          font-size: 13px;
          font-weight: 700;
          padding: 5px 15px 5px 15px;
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          margin: 20px 0 20px 0;
        }

        .userKeywords {
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 700;
        }
        .localKeywords {
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 700;
        }
        .keyworddes {
          padding-left: 8px;
          font-size: 13px;
          margin-bottom: 20px;
          color: #6e6e6e;
        }
      }
    }
  }
}
