@import '../../styles/common.module.scss';

.container {
}
.listContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0;

  @media (max-width: 976px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.listMoreBtn {
  border: none;
  border-radius: 100px;
  padding: 12px 30px;
  background-color: $main;
  color: $white;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.2s ease;

  &:hover {
    background-color: $mainhover;
  }
}
