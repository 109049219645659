@import '../../styles/common.module.scss';

.container {
}
.header {
  // background-color: rgb(229, 229, 229);
  padding: 10px 20px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  font-weight: 700;
  // border-top: 2px solid rgba(188, 188, 188, 0.6);
  // border-bottom: 2px solid rgba(188, 188, 188, 0.6);
  border-top: 2px solid black;
  border-bottom: 1px solid black;
}

.contents {
  padding: 20px 10px 10px 10px;
  // text-align: center;
  .contentsMessage {
    text-align: center;
  }
}

.chart_header {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}
.title {
  color: #8d8d8d;
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: $bg;
  }
}
.category {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8d8d8d;
}
.chartTitle {
  font-size: 14px;
  padding-top: 40px;
  text-align: center;
  color: #4d4d4d;
}
.chart {
  padding-top: 0px;
  // height: 300px;
}
.chartMessage {
  text-align: center;
  padding: 20px 0;
}
@media screen and (max-width: 576px) {
  .header {
    padding: 8px 10px;
    font-size: 13px;
    margin: 0 10px;
  }

  .contents {
    padding: 20px 0 0 0;
  }

  .chart_header {
    padding: 0 20px;
    font-size: 13px;
  }
  .chartTitle {
    font-size: 13px;
    padding-top: 30px;
  }

  .chart {
    padding-top: 0px;
    // height: 300px;
  }
}
