@import '../../styles/common.module.scss';

.container {
  padding: 0 30px;
}

.addInput {
  display: flex;
  justify-content: center;
  width: 100%; // 기본적으로 100%로 설정
  border-radius: 100px;
  border: 2px solid rgba(188, 188, 188, 0.5);
  // border-radius: 10px;
  padding: 2px;
  position: relative;
}

.inputWord {
  flex: 1;
  border: none;
  margin-left: 10px;
  font-size: 14px;

  &::placeholder {
    color: #a3a3a3;
  }
}

.inputBtn {
  border: none;
  border-radius: 100px;
  padding: 6px 10px 6px 8px;
  background-color: $main;
  color: $white;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: $mainhover;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0 10px;
  }

  .inputWord {
    font-size: 13px;
  }

  .inputBtn {
    padding: 6px 10px 6px 8px;
    font-size: 14px;
  }
}
