@import '../../styles/common.module.scss';

.container {
  display: flex;
  gap: 10px;
}

.selected_option {
  display: inline-block;
  margin-top: 5px;
  padding: 5px 10px;
  // box-shadow: 0 0 3px rgba(255, 101, 36, 0.8);
  border: 2px solid $main;
  border-radius: 8px;
  font-size: 14px;
  color: #333;

  .sub_option {
    // color: #666;
  }

  button {
    margin-left: 8px;
    background: transparent;
    border: none;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #ff0000;
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    gap: 4px;
    font-size: 14px;
  }

  .selected_option {
    margin-top: 3px;
    padding: 4px 8px;
    font-size: 12px;

    button {
      margin-left: 6px;
      &:hover {
        color: #ff0000;
      }
    }
  }
}
