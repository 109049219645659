@import '../../styles/common.module.scss';

.container {
  // max-width: 860px;
  width: 100%;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;

  .returnBtn {
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: #e3e3e3;
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 700;
    transition: all 0.2s ease;
    &:hover {
      background-color: #cfcfcf;
    }
  }

  .topRight {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .topLeft {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  header {
    font-size: 18px;
    font-weight: 500;
  }
}

.contents {
  border: 2px solid rgba(188, 188, 188, 0.6);
  border-radius: 10px;

  .storeInfo {
    padding: 30px 30px 20px 30px;
  }

  .btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 20px 30px;
    font-size: 14px;

    .keywordBtn {
      border: none;
      border-radius: 12px;
      padding: 12px 16px;
      background-color: $main;
      color: $white;

      font-weight: 700;
      transition: all 0.2s ease;
      &:hover {
        background-color: $mainhover;
      }
    }

    .keywordBtn:disabled {
      background-color: grey;
      cursor: not-allowed;
    }

    .kakaoBtn {
      border: none;
      border-radius: 12px;
      padding: 12px 16px;
      background-color: #fee500;
      color: #191919;

      font-weight: 500;
      transition: all 0.2s ease;
      &:hover {
        background-color: #d7c200;
      }
      & img {
        width: 14px;
        margin-right: 4px;
      }
      display: flex;
      align-items: center;
    }
    .btnRightBox {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .excelBtn {
      width: 32px;
      cursor: pointer;
      // border: 1px solid gray;
    }
  }
}

.dateContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  padding: 0 30px 20px 30px;
}

.disabledButton {
  opacity: 0.3; /* 반투명하게 표시 */
  cursor: not-allowed; /* 커서를 '금지' 심볼로 변경 */
  pointer-events: none; /* 클릭 이벤트 무시 */
}

.keyword_add_msg {
  padding: 30px;
  text-align: center;
  font-weight: 500;
}

/* modal overlay */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
  display: none;
}

.overlay.show {
  display: block;
}

@media screen and (max-width: 576px) {
  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .returnBtn {
      padding: 6px 8px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 6px;
    }

    .topRight {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 0;
    }
    .topLeft {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 10px;
      font-size: 12px;
    }

    header {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .contents {
    .storeInfo {
      padding: 10px;
    }

    .btnContainer {
      padding: 0 10px 0px 10px;
      font-size: 14px;

      .keywordBtn {
        border-radius: 6px;
        padding: 8px 8px;
        font-weight: 500;
      }

      .kakaoBtn {
        border-radius: 6px;
        padding: 8px 8px;
        font-weight: 500;

        & img {
          width: 12px;
          margin-right: 4px;
        }
      }
      .btnRightBox {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .excelBtn {
        width: 26px;
        cursor: pointer;
        // border: 1px solid gray;
      }
    }
  }

  .dateContainer {
    gap: 4px;
    padding: 10px 10px 10px 10px;
    font-size: 13px;
  }

  .keyword_add_msg {
    padding: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
  }
}
