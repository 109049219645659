@import '../../styles/common.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infoBox {
    display: flex;
    align-items: center;
    gap: 24px;

    .imgBox {
      img {
        width: 120px;
        border-radius: 10px;
      }
      .noImage {
        background-color: rgb(229, 229, 229);
        width: 120px;
        height: 120px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
      }
    }
    .infoDetail {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .storeName {
        font-weight: 700;
        font-size: 20px;
      }
      .storeAdr {
        font-weight: 500;
        font-size: 14px;
        color: #939393;
        margin-bottom: 4px;
      }

      .storeReview {
        display: flex;
        align-items: flex-end;
        font-weight: 500;
        gap: 6px;
        color: #8d8d8d;

        .reviewcount {
          font-size: 14px;
        }

        .reviewdiff {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .reviewdiffText {
          font-size: 12px;
          color: #b4b4b4;
        }
      }
    }
  }

  .keywordBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;

    .storeUpdate {
      color: #8d8d8d;

      display: flex;
      gap: 8px;
      align-items: center;

      span {
        color: #818181;
        font-weight: 500;
      }
    }

    .keyword {
      display: flex;
      justify-content: space-between;
      text-align: center;
      gap: 24px;
      margin-top: 20px;
    }
    .keywordTitle {
      color: #8d8d8d;
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .keywordCount {
      font-weight: 700;
      font-size: 26px;
      color: #444444;
    }
  }
}

@media screen and (max-width: 976px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .infoBox {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 100%;

      .imgBox {
        display: none;
      }
      .infoDetail {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .storeName {
          font-weight: 700;
        }
        .storeAdr {
          font-weight: 500;

          margin-bottom: 2px;
        }

        .storeReview {
          display: flex;
          align-items: flex-end;
          font-weight: 500;
          gap: 2px;

          span {
            color: #b4b4b4;
          }

          .reviewdiff {
            display: flex;
            align-items: center;
            gap: 2px;
          }
          .reviewdiffText {
            margin-left: 4px;
          }
        }
      }
    }

    .keywordBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      gap: 0px;
      width: 100%;
      margin-top: 0px;

      // gap: 30px;

      .storeUpdate {
        gap: 4px;

        justify-content: center;
        flex-direction: column;
        // width: 100%;
      }

      .keyword {
        display: flex;
        justify-content: flex-end;
        text-align: center;
        width: 100%;
        gap: 40px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .keywordTitle {
        color: #8d8d8d;
        font-weight: 500;
        margin-bottom: 4px;
      }
      .keywordCount {
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .infoBox {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 100%;

      .imgBox {
        display: none;
      }
      .infoDetail {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .storeName {
          font-weight: 700;
          font-size: 15px;
        }
        .storeAdr {
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 2px;
        }

        .storeReview {
          display: flex;
          align-items: flex-end;
          font-weight: 500;
          gap: 2px;
          font-size: 12px;

          span {
            color: #b4b4b4;
          }

          .reviewcount {
            font-size: 12px;
          }

          .reviewdiff {
            display: flex;
            align-items: center;
            gap: 2px;
          }

          .reviewdiffText {
            font-size: 10px;
            margin-left: 4px;
          }
        }
      }
    }

    .keywordBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      gap: 0px;
      width: 100%;
      margin-top: 0px;

      // gap: 30px;

      .storeUpdate {
        gap: 4px;
        font-size: 12px;
        justify-content: center;
        flex-direction: column;
        // width: 100%;
        svg {
          width: 14px;
          height: 14px;
        }
      }

      .keyword {
        display: flex;
        justify-content: flex-end;
        text-align: center;
        width: 100%;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .keywordTitle {
        color: #8d8d8d;
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .keywordCount {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
