@import '../../styles/common.module.scss';

.wrapper {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px 40px 60px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
}

.logo {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 40px;
  font-family: 'Cafe24Ohsquare';
}

.container label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
}

.container input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 1rem;
  color: #333;
  margin-top: 5px;
}

.container button {
  max-width: 160px;
  width: 100%;
  padding: 10px 0px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  background-color: $main;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: $mainhover;
  }
}

.container button:disabled {
  background-color: $mainhover;
  cursor: not-allowed;
}

.container p {
  padding: 4px;
  color: #f00;
  font-size: 0.8rem;
}

.submitErr {
  text-align: center;
  padding: 4px;
  color: #f00;
  font-size: 0.8rem;
}

.tag {
  border-bottom: 1px solid #ddd;
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 40px;

  span {
    font-weight: 500;
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    color: #696969;
    padding: 0 20px;
  }
}

.sign {
  margin-top: 40px;

  span {
    font-weight: 500;
    color: $main;
    cursor: pointer;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 30px 30px 30px 30px;
    max-width: 360px;
  }

  .logo {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .container label {
    margin-bottom: 20px;
    font-size: 1rem;
  }

  .container input {
    padding: 8px;
    font-size: 1rem;
  }

  .container button {
    max-width: 100px;
    padding: 10px 0px;
    margin-top: 10px;
    font-size: 16px;
  }

  .tag {
    margin-top: 30px;

    span {
      font-size: 14px;
    }
  }

  .sign {
    margin-top: 30px;
    font-size: 14px;
  }
}
