@import '../../styles/common.module.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
}

.searchBar {
  display: flex;
  justify-content: center;
  width: 100%; // 기본적으로 100%로 설정
  max-width: 600px;
  border-radius: 100px;
  border: 2px solid #bababa;
  padding: 3px;
  position: relative;
  margin-bottom: 20px;
}

.searchInput {
  flex: 1;
  border: none;
  margin-left: 10px;
  font-size: 14px;

  &::placeholder {
    color: #a3a3a3;
  }
}

.searchButton {
  border: none;
  border-radius: 100px;
  padding: 6px 10px 6px 8px;
  background-color: $main;
  color: $white;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: $mainhover;
  }
}

@media screen and (max-width: 576px) {
  .container {
    margin: 15px;
  }

  .searchBar {
    padding: 2px;
    margin-bottom: 10px;
    max-width: 400px;
  }

  .searchInput {
    margin-left: 6px;
    font-size: 13px;
  }

  .searchButton {
    padding: 6px 10px 6px 8px;
    font-size: 14px;
  }
}

// 리액트 아이콘 반응형 크기 조절
.searchButton {
  svg {
    width: 26px;
    height: 26px;
  }

  @media screen and (max-width: 576px) {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
