@import '../../styles/common.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 20px;
  min-height: 60vh;
}
.title {
  margin-top: 6vh;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 12px;
  font-family: 'Cafe24Ohsquare';
}

.logo {
  width: 410px;
}

@media screen and (max-width: 576px) {
  .container {
    padding: 40px 15px; /* 상하 여백 줄이고, 좌우 패딩 조정 */
    min-height: 50vh; /* 최소 높이 조정 */
  }

  .title {
    font-size: 24px; /* 제목 폰트 크기 줄임 */
    margin-bottom: 8px;
  }

  .logo {
    width: 280px; /* 로고 크기 조정 */
  }
}
