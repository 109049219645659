@import '../../styles/common.module.scss';

.container {
  width: 100%;
  padding: 0 20px;
}

.table {
  width: 100%;
  border-top: 2px solid black;
  table-layout: fixed;

  // border-bottom: 2px solid rgba(188, 188, 188, 0.6);

  thead th {
    border-bottom: 1px solid rgb(70, 70, 70);
    padding: 10px 20px 10px 20px;
  }

  th,
  td {
    text-align: left;
    vertical-align: middle;
    // padding: 20px 20px 20px 20px;
    // border-top: 2px solid rgba(188, 188, 188, 0.6);
    // border-bottom: 2px solid rgba(188, 188, 188, 0.6);
  }
  tbody th {
    padding: 20px 20px 20px 20px;
  }
  td {
    padding: 20px 20px 20px 20px;
    font-weight: 500;
    font-size: 15px;
  }

  th {
    font-weight: 700;
    // background-color: rgb(229, 229, 229);
    // border-bottom: 1px solid rgba(188, 188, 188, 0.6);
  }
}
.table_min {
  min-width: 680px;
}

.KeywordBox:not(:last-child) {
  border-bottom: 1px solid #dddddd; /* 원하는 border 스타일로 변경하세요 */
}

.listItem {
  margin-bottom: 30px; // adjust this value to change the space between items
}
.linkHover:hover {
  text-decoration: underline;
}

.keywordTitle {
  font-size: 15px;
  position: relative;
  height: 100%;

  .keywordName {
    cursor: pointer;
  }

  .btn {
    position: absolute;
    bottom: 20px;
    right: 0;
    color: rgb(60, 176, 253);
    font-size: 12px;
  }

  .btn .underlineText {
    text-decoration: underline;
  }
}
.keywordSearchCount {
  font-size: 14px;
  font-weight: 500;
}

.relKeywords {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
}

.hashTag {
  cursor: pointer;
  // margin-right: 4px;
  display: inline-block;
  white-space: nowrap;
  overflow-wrap: break-word;
  padding: 2px;
  color: #555555;
  // background-color: #f1f0f0;
  &:hover {
    color: $main;
  }
}

@media screen and (max-width: 976px) {
  /*normal*/
  .table_box {
    overflow-x: auto;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0 10px;
  }

  .table {
    thead th {
      padding: 8px 10px 8px 10px;
      font-size: 13px;
    }

    th,
    td {
    }
    tbody th {
      padding: 10px 10px 10px 10px;
    }
    td {
      padding: 10px 10px 10px 10px;
      font-size: 12px;
    }

    th {
      font-weight: 700;
      // background-color: rgb(229, 229, 229);
      // border-bottom: 1px solid rgba(188, 188, 188, 0.6);
    }
  }
  .table_min {
    min-width: 600px;
  }

  .listItem {
    margin-bottom: 15px; // adjust this value to change the space between items
  }

  .keywordTitle {
    font-size: 13px;

    .btn {
      position: absolute;
      bottom: 10px;
      right: 0;
      font-size: 10px;
    }

    .btn .underlineText {
      text-decoration: underline;
    }
  }
  .keywordSearchCount {
    font-size: 13px;
  }

  .relKeywords {
    margin-top: 10px;
    font-size: 11px;
  }
}
