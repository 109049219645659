@import '../styles/common.module.scss';

.wrapper {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px 40px 60px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
}

.logo {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 40px;
  font-family: 'Cafe24Ohsquare';
}

.text {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
}

.nav {
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  color: $main;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 576px) {
  .container {
    padding: 30px;
    max-width: 360px;
  }

  .logo {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .text {
    font-size: 1rem;
    line-height: 1.5;
  }

  .nav {
    font-size: 18px;
  }
}
