@import '../../styles/common.module.scss';
.container {
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 3px $main;
    scale: 1.005;
    // border: 2px solid $main;
  }
}
.name {
  font-size: 18px;
  color: $main;
  font-weight: 700;
}
.adr {
  // font-weight: 700;
  color: #383838;
}
.review {
  font-size: 14px;
  color: #666;
}

@media screen and (max-width: 576px) {
  .container {
    width: 320px;
    gap: 4px;
  }
  .name {
    font-size: 16px;
  }
  .adr {
    font-size: 14px;
  }
  .review {
    font-size: 12px;
  }
}
