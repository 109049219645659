@import '../../styles/common.module.scss';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 102;
  background-color: $white;
  padding: 30px 0;
  border-radius: 10px;
  width: 900px;
  max-height: 700px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.modalBody {
  padding: 0 30px;
  max-height: 480px;
  overflow-y: auto;
  border-top: 1px solid black;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    background: #b6b6b6;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #969696;
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}
.modalHeader {
  padding: 0 30px;
  margin-bottom: 20px;
}

.headerTitle {
  font-size: 18px;
  letter-spacing: -0.1px;
  font-weight: 700;
}

.headerDesc {
  font-size: 14px;
  color: #989da0;
  margin-top: 16px;
  margin-bottom: 8px;
}

.storeTitle {
  font-size: 14px;
  font-weight: 400;
}

.addInput {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.inputWord {
  font-size: 14px;
  width: 80%;
  line-height: 1.6;
  border: 1px solid #969696;
  border-radius: 5px;
  padding: 8px;

  &::placeholder {
    color: #a3a3a3;
  }
}

.inputBtn {
  width: 18%;
  border: none;
  border-radius: 100px;
  padding: 9px 16px;
  background-color: $main;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s ease;
  &:hover {
    background-color: $mainhover;
  }
}

.keywordList {
  font-size: 16px;
  margin-bottom: 13px;
  margin-top: 20px;
  font-weight: 700;
}

.listBox {
  border: 1px solid #969696;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 80%;
}

.keywordTag {
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #f7f9fd;
  font-size: 14px;
  font-weight: 700;
  color: #2f2f2f;
  text-align: center;
  padding: 7px 10px;
}

.tagDelete {
  border: none;
  background: none;
  margin-left: 6px;
}

.tableTitle {
  font-weight: 700;
  margin-bottom: 13px;
  margin-top: 20px;
  line-height: 1;
}

.externalKeywordsSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; // 바닥 여백 추가
  gap: 10px 20px; // 행과 열 사이의 간격 지정
  border: 1px solid #969696;
  border-radius: 5px;
  padding: 16px;

  .keywordItem {
    margin-right: 10px; // 오른쪽 여백 추가
    flex: 0 0 auto; // 키워드 항목이 자신의 내용에 맞게 크기를 조절하도록 설정
  }

  .keywordCheckBtn {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #bbb;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;

    &:checked {
      background-color: $main;
    }

    .black {
      color: black;
    }

    .gray {
      color: #888c8e;
    }
  }

  .keywordText {
    white-space: normal; // 텍스트가 필요할 때 자연스럽게 줄 바꿈
    word-break: break-word; // 필요한 경우 단어 내에서 줄 바꿈
    cursor: pointer;
    font-weight: 700;
  }
}

.table {
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  font-size: 16px;

  th,
  td {
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid black;
    height: 80px;
  }

  td {
    padding: 20px 20px 20px 20px;
  }

  th {
    border-right: 1px solid black;
    width: 140px;
    padding: 20px 10px 20px 20px;
    font-weight: 700;
  }
}

.keywordContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 20px;
}

.keywordItem {
  display: flex;
  align-items: center;
  gap: 4px;
}

.keywordCheckBtn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbb;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.keywordCheckBtn:checked {
  background-color: $main;
}

// .keywordCheckBtn:checked::after {
//   content: '';
//   background-color: $main;
//   display: block;
//   width: 10px;
//   height: 10px;
//   border-radius: 1px;
//   margin: 4px;
// }

// .keywordCheckBtn:focus {
//   outline: none;
//   box-shadow: 0 0 2px 1px #bbb;
// }

.keywordText {
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
}

.gray {
  color: #888c8e;
}

.black {
  color: black;
}

.noKeywordMessage {
  text-align: center;
  color: #888c8e;
  font-weight: 500;
  line-height: 1.2;
}

.modalBottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 0 20px;
}
.saveBtn {
  border: none;
  border-radius: 100px;
  padding: 12px 16px;
  background-color: $main;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s ease;
  &:hover {
    background-color: $mainhover;
  }
}

@media screen and (max-width: 976px) {
  .modal {
    width: 95%; // 모달 너비 조정
    // height: 95%;
    padding: 20px 0px; // 패딩 조정
  }
  .modalBody {
    padding: 0 30px;
    max-height: 440px;
  }

  .modalBody,
  .modalHeader {
    padding: 0 10px; // 내부 패딩 조정
  }

  .addInput {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .inputWord {
    font-size: 13px;
    width: 64%;
    line-height: 1.6;
    border: 1px solid #969696;
    border-radius: 5px;
    padding: 8px;
  }

  .inputBtn {
    width: 34%;
    padding: 9px 16px;
    font-size: 13px;
  }

  .listBox {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    width: 100%;
    font-size: 13px;
  }

  .keywordTag {
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 13px;
    padding: 7px 8px;
  }

  .tagDelete {
    margin-left: 4px;
  }

  .tableTitle {
    font-weight: 700;
    margin-bottom: 13px;
    margin-top: 20px;
    line-height: 1;
  }

  .externalKeywordsSection {
    width: 100%;
    margin-bottom: 20px; // 바닥 여백 추가
    gap: 6px; // 행과 열 사이의 간격 지정

    padding: 10px;

    .keywordItem {
      margin-right: 10px; // 오른쪽 여백 추가
      flex: 0 0 auto; // 키워드 항목이 자신의 내용에 맞게 크기를 조절하도록 설정
    }

    .keywordCheckBtn {
      width: 14px;
      height: 14px;
    }

    .keywordText {
      white-space: normal; // 텍스트가 필요할 때 자연스럽게 줄 바꿈
      word-break: break-word; // 필요한 경우 단어 내에서 줄 바꿈
      cursor: pointer;
      font-weight: 700;
      font-size: 13px;
    }
  }

  .keywordItem {
    gap: 4px;
  }

  .keywordCheckBtn {
    width: 14px;
    height: 14px;
  }

  .keywordContainer {
    grid-template-columns: repeat(2, 1fr); // 2열 레이아웃으로 변경
  }

  .modalBottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    padding: 0 10px;
  }
  .saveBtn {
    border: none;
    border-radius: 100px;
    padding: 12px 16px;
    background-color: $main;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    transition: all 0.2s ease;
    &:hover {
      background-color: $mainhover;
    }
  }
}

@media screen and (max-width: 576px) {
  .modal {
    // 모달의 더 작은 화면 대응 스타일
  }

  .modalHeader {
    margin-bottom: 10px;
  }

  .headerTitle {
    font-size: 16px;
  }

  .headerDesc {
    font-size: 13px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .storeTitle {
    font-size: 14px; // 상점 제목 폰트 크기 조정
  }

  .keywordList,
  .tableTitle {
    font-size: 14px; // 제목 폰트 크기 조정
  }

  .keywordContainer {
    grid-template-columns: 1fr; // 한 열 레이아웃으로 변경
  }

  .table {
    th {
      font-size: 14px; // 테이블 헤더 폰트 크기 조정
    }

    td {
      padding: 10px; // 셀 패딩 조정
      font-size: 14px; // 테이블 헤더 폰트 크기 조정
    }
  }

  .saveBtn {
    padding: 10px 14px; // 저장 버튼 패딩 조정
    font-size: 12px; // 저장 버튼 폰트 크기 조정
  }
}
