@import '../../styles/common.module.scss';

.container {
  // max-width: 860px;
  width: 100%;
  font-weight: 500;

  padding: 0 20px;
}

.header {
  font-size: 18px;
  font-weight: 500;
  img {
    display: inline-block;
    width: 20px;
  }

  span {
    margin-left: 8px;
  }
}

.storeInfo {
  padding: 20px 0;
}
.scoreInfo {
  padding-top: 10px;
}

.dateContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  // padding: 0 30px 20px 30px;
}
.disabledButton {
  opacity: 0.3; /* 반투명하게 표시 */
  cursor: not-allowed; /* 커서를 '금지' 심볼로 변경 */
  pointer-events: none; /* 클릭 이벤트 무시 */
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;

  .messageText {
    line-height: 1.2;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0;
  }

  .header {
    font-size: 16px;
    img {
      display: inline-block;
      width: 18px;
    }

    span {
      margin-left: 4px;
    }
  }

  .storeInfo {
    padding: 10px 0;
  }
  .scoreInfo {
    padding-top: 4px;
  }

  .dateContainer {
    font-size: 14px;
  }
  .disabledButton {
    opacity: 0.3; /* 반투명하게 표시 */
    cursor: not-allowed; /* 커서를 '금지' 심볼로 변경 */
    pointer-events: none; /* 클릭 이벤트 무시 */
  }

  .loadingContainer {
    gap: 20px;
    text-align: center;
    font-size: 14px;
    .messageText {
      font-size: 14px;
    }
  }
}
