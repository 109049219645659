@import '../../styles/common.module.scss';

.container {
}
.header {
  background-color: rgb(229, 229, 229);
  padding: 10px 30px;
  display: flex;
  align-items: center;
  font-weight: 700;
  // border-top: 2px solid rgba(188, 188, 188, 0.6);
  border-bottom: 2px solid rgba(188, 188, 188, 0.6);
}

.contents {
  padding: 20px 10px 10px 10px;
}

.chart_header {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}
.title {
  color: #8d8d8d;
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: $bg;
  }
}
.category {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8d8d8d;
}
.chart {
  padding-top: 30px;
  // height: 300px;
}
@media screen and (max-width: 576px) {
  .header {
    padding: 8px 10px;
    font-size: 13px;
  }

  .contents {
    padding: 20px 0 0 0;
  }

  .title {
    font-size: 13px;
  }

  .chart {
    padding-top: 20px;
    // height: 300px;
  }
}
