@import '../../styles/common.module.scss';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 102;
  background-color: $white;
  padding: 50px 20px 40px 20px;
  border-radius: 10px;
  width: 1200px;
  max-height: 700px;
  min-height: 300px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute; // 가상 요소의 위치를 지정
    top: 0;
    left: 0;
    right: 0;
    height: 50px; // 바의 높이
    background-color: $main; // 원하는 색상을 설정
    z-index: 101;
    display: flex;
    align-items: center;
  }

  .modalHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 103;
    display: flex;
    justify-content: space-between; // 이 부분 추가
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: 700;
    padding: 0 20px;

    .headerTitle {
      display: flex;
      align-items: center;
    }

    img {
      width: 32px;
      margin-right: 8px;
    }
  }

  .closeBtn {
    font-size: 24px;
    font-weight: 700;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.modalBody {
  margin-top: 30px;

  .storeLayout {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .imgBox {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 6px;
    }
    .noImage {
      background-color: rgb(229, 229, 229);
      width: 120px;
      height: 120px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
    }
  }

  .detail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .compareTableContainer {
      overflow-x: auto; // 가로 스크롤 활성화
      overflow-y: auto; // 세로 스크롤 활성화
      height: 100%;
      max-height: 80vh;
      width: 100%; // 컨테이너의 너비를 100%로 설정
    }

    .compareTable {
      table-layout: fixed;
      width: 100%;
      min-width: 1160px;
      // height:;
      border-collapse: collapse;
      border-left: none; // 좌측 바깥 테두리 제거
      border-right: none; // 우측 바깥 테두리 제거

      th,
      td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: center; // 텍스트 가운데 정렬
        box-sizing: border-box;
      }
      // 맨 첫 번째 행의 맨 위 테두리 색깔을 검은색으로 변경
      & > thead > tr:first-child > th {
        border-top: 1px solid black;
      }

      // 맨 마지막 행의 맨 아래 테두리 색깔을 검은색으로 변경
      & > tbody > tr:last-child > td,
      & > tbody > tr:last-child > th {
        border-bottom: 1px solid black;
      }

      // 모든 셀의 우측 테두리 색깔을 검은색으로 변경
      & th,
      & td {
        border-right: 1px solid black;
      }

      // 첫 번째 열의 모든 셀의 우측 테두리 색깔을 검은색으로 변경
      & > thead > tr > th:first-child,
      & > tbody > tr > td:first-child {
        border-right: 1px solid black;
      }

      // 첫 번째 열의 마지막 셀의 하단 테두리 색깔을 검은색으로 변경
      & > tbody > tr:last-child > td:first-child {
        border-bottom: 1px solid black;
      }

      th:first-child,
      td:first-child {
        border-left: none;
      }

      th:last-child,
      td:last-child {
        border-right: none;
      }

      & > thead > tr:nth-child(2) {
        // 2행을 선택
        background-color: rgb(251, 234, 226); // 원하는 배경색
      }
    }

    .innerTable {
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;

      th,
      td {
        border: none; // 바깥선 제거
        text-align: center;
        vertical-align: middle;
        border-right: 1px solid #ccc;
      }

      thead tr {
        border-bottom: 1px solid #ccc; // 가로 선 추가
        font-size: 14px;
      }

      th:last-child,
      td:last-child {
        border-right: none; // 마지막 셀의 오른쪽 세로선 제거
      }

      .subTitle {
        font-size: 12px;
        margin-left: 2px;
      }
    }

    .innerTable th {
      width: 33.33%;
    }

    .compareTable,
    .innerTable {
      th,
      td {
        text-align: center; // 가로 정렬
        vertical-align: middle; // 세로 정렬
      }
    }

    .storeName {
      font-size: 17px;
    }
    .keyword {
      // font-weight: 700; // 폰트 두께를 두꺼워지게 설정
      font-size: 17px;
    }

    .hotMain {
      color: $main; // 원하는 색상으로 변경
    }

    .message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 105; // z-index를 적절하게 설정해주세요.
      text-align: center;

      .messageText {
        margin-top: 20px;
        line-height: 1.4;
      }
    }
  }
}

@media screen and (max-width: 976px) {
  .modal {
    width: 95%; // 모달의 너비를 화면 너비에 맞게 조절
    max-height: 90%; // 모달의 최대 높이 조절
    padding: 30px 10px 20px 10px;

    .detail {
      .compareTable {
        min-width: 1160px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .modal {
    &::before {
      height: 40px; // 바의 높이
    }
    .modalHeader {
      font-size: 15px; // 헤더 폰트 사이즈 조절
      height: 40px;
      padding: 0 20px;
      img {
        width: 24px;
        margin-right: 8px;
      }
    }

    .closeBtn {
      font-size: 15px; // 닫기 버튼 크기 조절
    }

    .modalBody {
      margin-top: 30px;

      .storeLayout {
        gap: 10px;
      }
      .imgBox {
        img {
          width: 120px;
          height: 120px;
        }
        .noImage {
          width: 120px;
          height: 120px;
          font-size: 12px;
        }
      }
    }

    .detail {
      .compareTable {
        min-width: 976px;
        th,
        td {
          padding: 4px;
        }
      }

      .compareTable {
        th,
        td {
          font-size: 14px; // 테이블 내 텍스트 사이즈 조절
        }
      }
      .innerTable {
        th {
          font-size: 12px;
        }
        td {
          font-size: 14px; // 테이블 내 텍스트 사이즈 조절
        }
      }
      .storeName {
        font-size: 14px;
      }
      .keyword {
        font-size: 14px;
      }

      .innerTable {
        .subTitle {
          font-size: 10px;
          margin-left: 2px;
        }
      }
    }

    .message {
      font-size: 14px;
      width: 100%;
      .messageText {
        width: 100%;
        margin-top: 20px;
        line-height: 1.4;
      }
    }
  }
}
