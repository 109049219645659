@import '../../styles/common.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 20px;
  min-height: 60vh;
}

.logo {
  margin-top: 6vh;
  font-size: 48px;
  font-weight: 700;
  font-family: 'Cafe24Ohsquare';
  margin-bottom: 30px;
}

.switch {
  display: flex;
  justify-content: center;
}
.switchLeft {
  background-color: #e3e3e3;
  color: #6b6b6b;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50px;
  padding: 14px 84px 14px 50px;
  margin-right: -60px;
  cursor: pointer;

  &:hover {
    color: $main;
  }
}
.switchRight {
  background-color: $main;
  color: $white;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50px;
  padding: 14px 50px 14px 50px;
  z-index: 1;
}

@media screen and (max-width: 576px) {
  .container {
    padding: 40px 15px;
  }
  .logo {
    font-size: 36px;
    margin-bottom: 15px;
  }

  .switchLeft {
    background-color: #e3e3e3;
    color: #6b6b6b;
    font-size: 16px;
    padding: 10px 74px 10px 40px;
    margin-right: -60px;
  }
  .switchRight {
    padding: 10px 40px 10px 40px;
    font-size: 16px;
  }
}
