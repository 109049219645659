@import '../../styles/common.module.scss';

.container {
  // max-width: 920px;
  width: 100%;
  font-weight: 500;
  // padding: 0 20px;
}

.header {
  font-size: 18px;
  font-weight: 500;
  img {
    display: inline-block;
    width: 20px;
  }

  span {
    margin-left: 8px;
  }
}

.storeInfo {
  padding: 20px 0;
}
.scoreInfo {
  padding-top: 10px;
}
