@import '../../styles/common.module.scss';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 102;
  background-color: $white;
  padding: 20px 30px 40px 30px;
  border-radius: 10px;
  width: 820px;
  max-height: 700px;
  // height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
.modalHeader {
  margin-bottom: 20px;
  font-size: 18px;

  strong {
    color: $main;
  }
}

.modalBody {
  padding-top: 20px;
  max-height: 600px;
  height: 580px;
  overflow-y: auto;
  border-top: 1px solid black;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    background: #b6b6b6;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #969696;
  }

  .bodyTop {
    display: flex;
    gap: 40px;
    .imgBox {
      min-width: 280px;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
      }
      .noImage {
        background-color: rgb(229, 229, 229);
        width: 280px;
        height: 200px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0;
      height: 200px;

      .title {
        font-size: 20px;
        padding-right: 20px;
        line-height: 1.2;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      .score {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 20px;
        column-gap: 60px;
        padding-right: 30px;

        .scoreText {
          color: #969696;
        }
        .scoreNum {
          font-size: 20px;
        }

        .textLeft {
          text-align: left;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .textCenter {
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 6px;
        }
      }
    }
  }

  .subTitle {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .scoreContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .scoreBox {
    border: 1px solid #999999;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    text-align: center;
    padding: 20px 16px;

    .text {
      font-size: 18px;
    }
    .textNum {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}

@media screen and (max-width: 976px) {
  .modal {
    width: 95%; // 모달 너비 조정
    padding: 20px 10px 20px 10px;
  }

  .modalHeader {
    font-size: 16px; // 모달 헤더의 폰트 사이즈를 줄임
  }

  .modalBody {
    padding-top: 10px;
    max-height: 380px;
    .bodyTop {
      flex-direction: column; // 요소들을 세로로 쌓음
      align-items: flex-start; // 가운데 정렬
      gap: 20px; // 요소 사이의 간격 조정

      .imgBox {
        display: none;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        height: auto;
        gap: 20px;

        .title {
          font-size: 15px;
          padding-right: 0px;
          line-height: 1.2;
        }

        .score {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          row-gap: 10px;
          column-gap: 20px;
          padding-right: 0px;
          padding-left: 10px;

          .scoreText {
            color: #969696;
            font-size: 13px;
          }
          .scoreNum {
            font-size: 15px;
          }

          .textLeft {
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          .textCenter {
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 6px;
          }
        }
      }
    }

    .subTitle {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .scoreContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-right: 0px;

      // grid-template-columns: 1fr; // 스코어 컨테이너를 한 열로 만듦

      gap: 10px;
    }
    .scoreBox {
      border: 1px solid #999999;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      text-align: center;
      padding: 10px;

      .text {
        font-size: 13px;
      }
      .textNum {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .modal {
    width: 95%; // 모달 너비 조정
    padding: 20px 10px 20px 10px;
  }

  .modalHeader {
    font-size: 14px; // 모달 헤더의 폰트 사이즈를 더 줄임
  }

  .modalBody {
    .bodyTop {
    }

    .info {
      .title {
        font-size: 16px; // 타이틀 폰트 사이즈를 더 줄임
      }
    }

    .scoreContainer {
    }
  }
}
